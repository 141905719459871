import Vue from 'vue'
import App from './App.vue'

import VueKatex from 'vue-katex';
import 'katex/dist/katex.min.css';

import vuetify from './plugins/vuetify';
import VueClipboard from 'vue-clipboard2'

import katex from "katex";

Vue.use(VueKatex, {
  globalOptions: {
    //... Define globally applied KaTeX options here
  }
});
Vue.use(VueClipboard);

Vue.prototype.$katex = katex;


Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
