import axios from 'axios';
import qs from 'qs';

class ApiService {
    constructor() {
        const options = {};
        options.baseURL = 'https://cors-maths.herokuapp.com/https://www.symbolab.com';
        this.token = '';
        this.instance = axios.create(options);
    }
    setToken(token) {
        this.token = token;
    }
    getToken() {
        return this.token;
    }
    async logInAndSetToken() {
        const response = await this.instance.post(
            '/login',
            qs.stringify({
                email: 'james.tophoven@gmail.com',
                password: 'Hunter123$',
                url: 'https://www.symbolab.com/#',
                remember: 'true',
            }),
            {
                headers: {
                    'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64; rv:85.0) Gecko/20100101 Firefox/85.0',
                    Accept: '*/*',
                    'Accept-Language': 'en-US,en;q=0.5',
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }
        );
        console.log('token: ' + response.data.jwt);
        this.token = response.data.jwt;
        return true;
    }
    async checkToken() {
        if (this.token == undefined || this.token == '') {
            console.log('no valid token set');
            return false;
        }

        const response = await this.instance.get('/api/user/settings', {
            headers: {
                'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64; rv:85.0) Gecko/20100101 Firefox/85.0',
                Accept: '*/*',
                'Accept-Language': 'en-US,en;q=0.5',
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: 'Bearer ' + this.token,
            },
        });
        return response.status==200;

    }

    async genericRawApiCall(formula){
        const response = await this.instance.get(
            '/pub_api/steps?subscribed=true&language=en&query=' +
                encodeURIComponent(formula) +
                '&page=step-by-step',
            {
                headers: {
                    'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64; rv:85.0) Gecko/20100101 Firefox/85.0',
                    Accept: '*/*',
                    'Accept-Language': 'en-US,en;q=0.5',
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: 'Bearer ' + this.token,
                },
            }
        );
        console.log(response);
        return response.data;
    }

    async genericApiCall(formula, verb) {
        const response = await this.instance.get(
            '/pub_api/steps?subscribed=true&language=en&query=' +
                encodeURIComponent(verb + ' of (' + formula + ')') +
                '&page=step-by-step',
            {
                headers: {
                    'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64; rv:85.0) Gecko/20100101 Firefox/85.0',
                    Accept: '*/*',
                    'Accept-Language': 'en-US,en;q=0.5',
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: 'Bearer ' + this.token,
                },
            }
        );
        console.log(response);
        return response.data;
    }

    async getDomain(formula) {
        const response = await this.genericApiCall(formula, 'domain');
        return response.solutions;
    }

    async getSymmetry(formula) {
        const response = await this.genericApiCall(formula, 'symmetry');
        return response.solutions;
    }

    async getRoots(formula) {
        const response = await this.genericApiCall(formula, 'roots');
        return response.solutions;
    }
    async getAsymptotes(formula) {
        const response = await this.genericApiCall(formula, 'asymptotes');
        return response.solutions;
    }
    async getDerivation(formula) {
        const response = await this.genericApiCall(formula, '\\frac{d^3}{dx^3}');
        return response.solutions;
    }
    async getExtremes(formula) {
        const response = await this.genericApiCall(formula, 'extreme points');
        return response.solutions;
    }
    async getInflections(formula) {
        const response = await this.genericApiCall(formula, 'inflection points');
        return response.solutions;
    }
    async getRange(formula) {
        const response = await this.genericApiCall(formula, 'range');
        return response.solutions;
    }

    async getUser() {
        console.log(this.instance);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get('/api/user', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async getUserTasks() {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get('/api/user/task', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            return response;
        } catch (error) {
            console.log(error);
        }
    }
    get currentUser() {
        return this.getUser();
    }
}
export default new ApiService();
