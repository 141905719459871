var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.solutionData.steps ||
    _vm.solutionData.title ||
    _vm.solutionData.general_rule ||
    _vm.solutionData.entire_result ||
    _vm.solutionData.step_input ||
    _vm.solutionData.step_output ||
    _vm.solutionData.explanation
  )?_c('v-expansion-panel',{style:(_vm.indent),attrs:{"readonly":_vm.solutionData.steps == undefined}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":_vm.solutionData.steps == undefined ? '' : 'mdi-menu-down'}},[(_vm.optional_chain(_vm.solutionData, 'explanation'))?_c('span',{directives:[{name:"katex",rawName:"v-katex",value:(_vm.addSpaces(_vm.solutionData.explanation[0].createdText)),expression:"addSpaces(solutionData.explanation[0].createdText)"}],staticClass:"pa-2"}):_vm._e(),(_vm.optional_chain(_vm.solutionData, 'title', 'text', 'createdText'))?_c('div',{staticClass:"d-flex flex-row"},[_c('div',{directives:[{name:"katex",rawName:"v-katex",value:(_vm.addSpaces(_vm.solutionData.title.text.createdText)),expression:"addSpaces(solutionData.title.text.createdText)"}]}),(
          _vm.optional_chain(_vm.solutionData, 'general_rule', 'text', 'createdText')
        )?_c('v-chip',{directives:[{name:"katex",rawName:"v-katex",value:(_vm.solutionData.general_rule.text.createdText),expression:"solutionData.general_rule.text.createdText"}],staticClass:"ml-2 mr-2"}):_vm._e()],1):_vm._e(),(
        _vm.solutionData.entire_result &&
        !(_vm.solutionData.step_input && _vm.solutionData.step_output)
      )?_c('div',{staticClass:"mt-2 mb-2"},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","label":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(_vm.solutionData.entire_result)}}},[_c('span',{directives:[{name:"katex",rawName:"v-katex",value:(_vm.solutionData.entire_result),expression:"solutionData.entire_result"}],staticClass:"pa-2"})])],1):_vm._e(),(_vm.solutionData.step_input && _vm.solutionData.step_output)?_c('div',{staticClass:"d-flex flex-row"},[_c('div',{directives:[{name:"katex",rawName:"v-katex",value:(_vm.solutionData.step_input),expression:"solutionData.step_input"}]}),_c('span',{directives:[{name:"katex",rawName:"v-katex",value:('\\Rightarrow'),expression:"'\\\\Rightarrow'"}],staticClass:"ml-2 mr-2"}),_c('div',{directives:[{name:"katex",rawName:"v-katex",value:(_vm.solutionData.step_output),expression:"solutionData.step_output"}]})]):_vm._e()]),(_vm.solutionData.steps)?_c('v-expansion-panel-content',_vm._l((_vm.solutionData.steps),function(step,index){return _c('RecursiveSolution',{key:index,attrs:{"solutionData":step,"depth":_vm.depth + 1}})}),1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }