<template>
  <v-app>
    <v-app-bar app prominent>
      <v-avatar size="100">
        <img src="./assets/gauss.gif" />
      </v-avatar>
      <template v-slot:extension>

        <v-tabs v-model="tab">
          <v-tab> Kurvendiskussion </v-tab>
          <v-tab> Taylor </v-tab>
          <v-tab> Alles </v-tab>
        </v-tabs>
      </template>

      <v-container class="pt-10">
        <transition name="fade" mode="out-in">
          <v-row v-if="tab != 1" key="save">
            <v-text-field
              clearable
              :value="searchText"
              @input="searchText = $event ? $event : ''"
              @keyup.native="preview"
              @keyup.enter.native="onEnter"
              style="max-width: 500px"
              class="pt-5"
            >
            </v-text-field>
            <v-btn fab dark @click="onEnter" class="mt-5 ml-5"> Go </v-btn>
          </v-row>
          <v-row v-else key="test">
            <v-text-field
              style="max-width: 500px"
              class="pt-5"
              :value="searchText"
              @input="searchText = $event ? $event : ''"
              @keyup.native="preview"
              @keyup.enter.native="onEnter"
            ></v-text-field>
            <span style="font-size: 0.7em" class="pt-8" v-katex="', on\\:x_0='">
            </span>
            <v-text-field
              style="max-width: 200px"
              class="pt-5"
              :value="searchTextAdditional"
              @input="searchTextAdditional = $event ? $event : ''"
              @keyup.enter.native="onEnter"
            ></v-text-field>
            <v-btn fab dark @click="onEnter" class="mt-5 ml-5"> Go </v-btn>
          </v-row>
        </transition>
      </v-container>
    </v-app-bar>

    <v-main>
      <div>
        <v-container class="pt-10">
          <template v-if="searchText.length > 0">
            <span
              ><span
                v-html="
                  $katex.renderToString('f\\left(x\\right)=') +
                  formattedSearchText
                "
              ></span
            ></span>
                    <v-btn  class="ml-10" @click="openWolfram">WolframAlpha</v-btn>

          </template>

          <template v-if="tab == 0">
            <v-row v-for="(component, index) in componentNames" :key="index">
              <div class="d-flex flex-column ma-6">
                <ApiSolutionWrapper
                  :title="component.title"
                  :formula="formulaDiscussion"
                  :apiAction="component.apiAction"
                ></ApiSolutionWrapper>
              </div>
            </v-row>
          </template>
          <template v-else-if="tab == 1">
            <v-row>
              <div class="d-flex flex-column ma-6">
                <ApiSolutionWrapper
                  :title="'Vereinfachen'"
                  :formula="formulaTaylor.formula"
                  :apiAction="'simplify'"
                ></ApiSolutionWrapper>
              </div>
            </v-row>
            <v-row>
              <div class="d-flex flex-column ma-6">
                <TaylorSolutionWrapper
                  :title="'Taylor'"
                  :formula="formulaTaylor"
                ></TaylorSolutionWrapper>
              </div>
            </v-row>
          </template>
          <template v-else-if="tab == 2">
            <v-row>
              <div class="d-flex flex-column ma-6">
                <ApiRawSolutionWrapper
                  :title="'Lösung'"
                  :formula="formulaAll"
                ></ApiRawSolutionWrapper>
              </div>
            </v-row>
          </template>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import katex from "katex";
import ApiService from "./services/ApiService.js";

import ApiSolutionWrapper from "./components/ApiSolutionWrapper.vue";
import TaylorSolutionWrapper from "./components/TaylorSolutionWrapper.vue";
import ApiRawSolutionWrapper from "./components/ApiRawSolutionWrapper.vue";

export default {
  name: "App",
  components: {
    ApiSolutionWrapper,
    TaylorSolutionWrapper,
    ApiRawSolutionWrapper,
  },
  data() {
    return {
      componentNames: [
        { title: "Vereinfachen", apiAction: "simplify" },
        { title: "1. Definitionsbereich", apiAction: "domain" },
        { title: "2. Symmetrie", apiAction: "parity" },
        { title: "3. Nullstellen", apiAction: "roots" },
        //{title:"4. Polstellen", apiAction:'poles'},
        { title: "5. Asymptoten", apiAction: "asymptotes" },
        { title: "6. Ableitungen", apiAction: "\\frac{d^3}{dx^3}" },
        { title: "7. Extremstellen", apiAction: "extreme points" },
        { title: "8. Wendepunkte", apiAction: "inflection points" },
        { title: "9. Wertebereich", apiAction: "range" },
      ],
      tab: null,
      searchTextError: false,
      searchText: "",
      searchTextAdditional: "",
      formattedSearchText: "",
      authToken: "",
      isLoading: true,
      loggedIn: false,
      formulaDiscussion: "",
      formulaTaylor: {},
      formulaAll: "",
    };
  },

  async mounted() {
    try {
      if (localStorage.authToken) {
        ApiService.setToken(localStorage.authToken);
      }
      if (!(await ApiService.checkToken())) {
        await ApiService.logInAndSetToken();
        await ApiService.checkToken();
      }
      localStorage.authToken = ApiService.getToken();
      this.loggedIn = true;
    } catch (error) {
      console.log("error: " + error);
    }
    this.loggedIn = true;
    this.isLoading = false;
  },
  methods: {
    openWolfram() {
      window.open(
        "https://www.wolframalpha.com/input/?i=" +
          encodeURIComponent("plots " + this.searchText),
        "_blank"
      );
    },
    preview() {
      try {
        this.searchTextError = false;
        this.formattedSearchText = katex.renderToString(
          this.searchText.replaceAll(" ", "\\ ")
        );
      } catch (e) {
        console.log("Error in LaTeX '" + this.searchText + "': " + e.message);
        this.searchTextError = true;
      }
    },
    onEnter() {
      this.searchTextError = false;
      try {
        this.formattedSearchText = katex.renderToString(
          this.searchText.replaceAll(" ", "\\ ")
        );
      } catch (e) {
        console.log("Error in LaTeX '" + this.searchText + "': " + e.message);
        this.searchTextError = true;
        return;
      }
      switch (this.tab) {
        case 0:
          this.formulaDiscussion = this.searchText;
          break;
        case 1:
          if (this.searchTextAdditional.length < 1) {
            console.log("Error: no additional parameter");
            this.searchTextError = true;
            return;
          }
          this.formulaTaylor = {
            formula: this.searchText,
            point: this.searchTextAdditional,
          };
          break;
        case 2:
          this.formulaAll = this.searchText;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.v-chip.v-size--default {
  font-size: initial;
  height: initial;
}
</style>

