<template>
  <v-expansion-panel
    :style="indent"
    :readonly="solutionData.steps == undefined"
    v-if="
      solutionData.steps ||
      solutionData.title ||
      solutionData.general_rule ||
      solutionData.entire_result ||
      solutionData.step_input ||
      solutionData.step_output ||
      solutionData.explanation
    "
  >
    <v-expansion-panel-header
      :expand-icon="solutionData.steps == undefined ? '' : 'mdi-menu-down'"
    >
      <span
        v-if="optional_chain(solutionData, 'explanation')"
        v-katex="addSpaces(solutionData.explanation[0].createdText)"
        class="pa-2"
      >
      </span>
      <div
        class="d-flex flex-row"
        v-if="optional_chain(solutionData, 'title', 'text', 'createdText')"
      >
        <div v-katex="addSpaces(solutionData.title.text.createdText)"></div>
        <v-chip
          v-if="
            optional_chain(solutionData, 'general_rule', 'text', 'createdText')
          "
          v-katex="solutionData.general_rule.text.createdText"
          class="ml-2 mr-2"
        ></v-chip>
      </div>
      <div
        v-if="
          solutionData.entire_result &&
          !(solutionData.step_input && solutionData.step_output)
        "
        class="mt-2 mb-2"
      >
        <v-chip
          class="ma-2"
          color="primary"
          label
          @click.stop="copyToClipboard(solutionData.entire_result)"
        >
          <span v-katex="solutionData.entire_result" class="pa-2"> </span>
        </v-chip>
      </div>

      <div
        class="d-flex flex-row"
        v-if="solutionData.step_input && solutionData.step_output"
      >
        <div v-katex="solutionData.step_input"></div>
        <span class="ml-2 mr-2" v-katex="'\\Rightarrow'" />
        <div v-katex="solutionData.step_output"></div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="solutionData.steps">
      <RecursiveSolution
        v-for="(step, index) in solutionData.steps"
        :key="index"
        :solutionData="step"
        :depth="depth + 1"
      >
      </RecursiveSolution>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "RecursiveSolution",
  props: ["solutionData", "depth"],
  computed: {
    indent() {
      return { transform: `translate(${this.depth * 3}px)` };
    },
  },
  methods: {
    optional_chain(...parameters) {
      const [source, ...properties] = parameters;
      return properties.reduce(
        (object, property) => object?.[property] ?? undefined,
        source
      );
    },
    addSpaces(data) {
      return data.replaceAll("<br/>", "\\\\").replaceAll(" ", "\\ ");
    },
    copyToClipboard(entire_result) {
      this.$copyText(entire_result);
    },
  },
  filters: {
    //   addSpaces(data) {
    //       return data.replace(" ", "\\ ");
    //   }
  },
};
</script>

<style>
</style>
