<template>
  <div style="min-width: 500px; min-height: 150px">
    <v-progress-linear
      :active="isLoading"
      :indeterminate="isLoading"
      rounded
      height="6"
      dark
      prominent
    ></v-progress-linear>
    <template v-if="!isLoading && formula && formula.length > 0">
      <h1 class="title">{{ title }}</h1>
      <v-expansion-panels multiple>

        <RecursiveSolutionCard
          v-for="(solution, index) in solutions"
          :key="index"
          :depth="0"
          :solutionData="solution"
        />
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
import ApiService from "../services/ApiService.js";
import RecursiveSolutionCard from "./RecursiveSolutionCard.vue";

export default {
  name: "ApiRawSolutionWrapper",
  props: ["formula", "title"],
  components: { RecursiveSolutionCard },
  data() {
    return {
      solutions: [],
      isLoading: false,
    };
  },
  watch: {
    async formula(newVal) {
      this.isLoading = true;
      if (newVal == "") {
        this.solutions = [];
      } else {
        const result = await ApiService.genericRawApiCall(newVal);
        this.solutions = result.solutions;
      }
      this.isLoading = false;
    },
  },
  methods: {},
};
</script>

<style>
</style>